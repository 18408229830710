<!-- start footer -->

<footer class="footer" style="background-image: url(assets/layout/images/counterbg.png); background-position: center;">
    <div class="bg-overlay position-absolute top-0 end-0 bottom-0 start-0"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-12">
                <div>
                    <img src="assets/layout/images/logo-light.png" alt="logo" height="22" />
                </div>
                <h6 class="text-white mb-4 mt-sm-0 mt-4">{{"footer.findUs" | translate }}</h6>
                <p class="mt-4">
                    Tegucigalpa, M.D.C., Residencial Miramontes, Condominios Miramontes,
                    Continental 2do. Nivel, local 13  Tel (+504) 2235-7454
                </p>
            </div>
            <div class="col-lg-3 col-sm-12">
            </div>
            <div class="col-lg-5 col-sm-12">
                <div class="row">
                    <div class="col-12">
                        <h6 class="text-white mb-4 mt-sm-0 mt-5">{{"footer.contactUs" | translate }}</h6>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <ul class="list-unstyled footer-list">
                            <li>info@inversioneslosangeles.com</li>
                        </ul>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <ul class="list-unstyled footer-list">
                            <li>Tegucigalpa: 2235-7454</li>
                            <li>SPS: (+504) 2552-3053</li>
                            <li>Ceiba: (+504)  2440-2081</li>
                            <li>Choluteca: (+504) 2780-0230</li>
                            <li>Comayagua: 2772-4698</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</footer>
<!-- end footer -->

<!-- start footer alter -->
<div class="footer-alt bg-dark">
    <div class="bg-overlay position-absolute top-0 end-0 bottom-0 start-0"></div>
    <div class="container">
        <div class="row">
            <div class="col-sm-6">
                <p>{{cur_year}} © Inversiones Los Angeles. All rights Reserved.</p>
            </div>
            <div class="col-sm-6 text-sm-end">
                <ul class="list-unstyled footer-alt-list mb-0 mt-sm-0 mt-3">
                    <li class="list-inline-item">
                        <a href="https://www.facebook.com/CA-Corporaci%C3%B3n-SA-891630417852686;" [ngxScrollTo]="'#home'">
                            <i-feather name="facebook" class="icon-xs"></i-feather>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
<!-- end footer alter -->