// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyD-Ymp2SpkjlHwsj4jNlosRMKjL7z02tjE",
    authDomain: "pagina-inicio-b9592.firebaseapp.com",
    projectId: "pagina-inicio-b9592",
    storageBucket: "pagina-inicio-b9592.appspot.com",
    messagingSenderId: "455846397473",
    appId: "1:455846397473:web:a9fd67c36d54d0c72f8560",
    measurementId: "G-DCWDTWWNF8"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
