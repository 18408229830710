import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ScrollspyDirective } from './scrollspy.directive';
import { CountToModule } from 'angular-count-to';
import { NgbAccordionModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    ScrollspyDirective,
    FooterComponent,
    NavbarComponent
  ],
  imports: [
    CommonModule,
    CarouselModule,
    ScrollToModule.forRoot(),
    FeatherModule.pick(allIcons),
    CountToModule,
    NgbAccordionModule,
    NgbRatingModule,
    NgbModule,
    TranslateModule,
  ],
  exports: [
    FooterComponent, 
    FeatherModule, 
    ScrollspyDirective, 
    NavbarComponent,
    TranslateModule,
  ]
})
export class SharedModule { }